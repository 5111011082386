@tailwind utilities;

@layer utilities {
  .datetime-picker {
    @apply block w-full h-full relative;
    border: none;
    color: transparent;
  }

  .datetime-picker::-webkit-calendar-picker-indicator {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    color: transparent;
    opacity: 0;
  }

  .datetime-picker::before {
    @apply text-b1 text-content-inverseSecondary;
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: #fdfdfd;
  }

  .datetime-picker:valid {
    color: initial;
  }

  .datetime-picker:valid::before {
    display: none;
  }
}
